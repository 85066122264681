import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { getAuth } from "firebase/auth";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/protected",
    name: "Protect",
    component: () => import("../views/Messages.vue"),
    meta: {
      title: "Protected",
      authRequired: true,
    },
  },
  {
    path: "/send",
    name: "Send",
    component: () => import("../views/sendMessage.vue"),
    meta: {
      title: "Protected",
      authRequired: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (to.meta.authRequired) {
    if (user) {
      next();
    } else {
      router.push("/");
    }
  } else {
    next();
  }
});
export default router;
