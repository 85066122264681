import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
const firebaseConfig = {
  apiKey: "AIzaSyBiNs0cJcsE7UduhdORWzUZxgqmDzeTwaM",
  authDomain: "fireblogs-5776d.firebaseapp.com",
  projectId: "fireblogs-5776d",
  storageBucket: "fireblogs-5776d.appspot.com",
  messagingSenderId: "441045254999",
  appId: "1:441045254999:web:e1ceca0adc35ab11eaf412",
  databaseURL:
    "https://fireblogs-5776d-default-rtdb.asia-southeast1.firebasedatabase.app",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const database = getDatabase(app);
const auth = getAuth(app);
export { auth, db, database };
