<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
    <router-link v-if="this.$store.state.user" to="/protected"
      >Protected</router-link
    >
    <router-link v-if="this.$store.state.user" to="/send"
      >Send Messages</router-link
    >
    <button
      v-if="this.$store.state.user"
      @click="this.$store.dispatch('signout')"
    >
      Sign Out
    </button>
    <div v-if="this.$store.state.user">
      {{ this.$store.state.user.email }}
    </div>
  </nav>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
