import { createStore } from "vuex";
import { auth } from "@/firebase/firebaseInit";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
const store = createStore({
  state: {
    user: null,
    authReady: false,
  },
  mutations: {
    updateUser(state, payload) {
      state.user = payload;
      console.log(state.user);
    },
    updateAuthReady(state, payload) {
      state.authReady = payload;
    },
  },
  actions: {
    async signup(context, { email, password }) {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      if (res) context.commit("updateUser", res.user);
      else throw new Error("could not connect to server");
    },
    async signin(context, { email, password }) {
      const res = await signInWithEmailAndPassword(auth, email, password);
      if (res) context.commit("updateUser", res.user);
      else throw new Error("could not connect to server");
    },
    async signout(context) {
      console.log("signOut User");
      await signOut(auth);
      context.commit("updateUser", null);
    },
  },
});
const unsub = onAuthStateChanged(auth, (user) => {
  store.commit("updateAuthReady", true);
  store.commit("updateUser", user);
  unsub();
});
export default store;
