<template>
  <div class="home">
    <form @submit.prevent="handleSubmit">
      <h3>Login</h3>

      <label for="email">Email:</label>
      <input type="email" name="email" v-model="email" required />

      <label for="email">Password:</label>
      <input type="password" name="password" v-model="password" required />

      <button>Login</button>
      <div v-if="error">{{ error }}</div>
    </form>
  </div>
</template>

<script>
export default {
  name: "LoginPage",
  data() {
    return {
      email: "",
      password: "",
      error: "",
    };
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$store.dispatch("signin", {
          email: this.email,
          password: this.password,
        });
        this.$router.push("/");
      } catch (err) {
        this.error = err.message;
      }
    },
  },
};
</script>
